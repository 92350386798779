import React from "react";
import carFleetImg from "../images/carFleet.png"
import ButtonAction from "./ButtonAction";
import { navigate } from "gatsby";

class CarFleet extends React.Component {
    constructor(){
        super();
    }

    goTo(e){
        e.preventDefault();
        navigate("/replacementCar/")
    }

    render() {
        return(
            
            <div className="w-100 d-flex flex-row background-blue-gradient mb-8">
                <div className=" d-flex flex-row">
                <div className="text-wrapper d-flex flex-column w-50 main-padding-left quarter-main-padding-right">
                    <h1 className="white-color mt-4">
                        Samochód zastępczy - taki sam lub lepszy
                    </h1>
                    <div className="line__box d-flex min-height">
                        <div className="line__dasshed line__dashed--white w-100"></div>
                    </div>
                    <p className="white-color">Martwisz się, że dostaniesz na czas naprawy swojego samochodu auto gorsze? Nic z tych rzeczy! Każdy samochód można zaliczyć do jednego z 10 segmentów (klas) - u nas otrzymasz pojazd z tego samego segmentu lub wyższego.</p>
                    <p className="white-color text-uppercase">Nie wierzysz?</p>
                    <div className="d-flex justify-content-center mt-4 mb-xs-4">
                        <ButtonAction styleClass='white-bg main-color' label={'Zobacz nasze samochody >'} onClick={e => this.goTo(e)}></ButtonAction>
                    </div>
                </div>
                <img className="w-50 xs-main-padding mb-xs-4 d-flex" src={carFleetImg} alt=""></img>

            </div>
            </div>
        );
    }
}

export default CarFleet;