import React from "react";

import towing from "../images/towing.png";
import repair from "../images/repair.png";
import rental from "../images/rental.png";
import insurance from "../images/insurance.png";
import ButtonAction from "./ButtonAction";
import { navigate } from "gatsby";


class IconBar extends React.Component {

    goTo(e){
        e.preventDefault();
        navigate("/contact/")
    }

    render() {
        return(
            <div className="mt-8">
                <h2>Z AutoPomOCnymi zyskujesz:</h2>
                <div className="w-50">
                <div className="line__box d-flex mt-2 mb-8">
                    <div className="line__dashed line__dashed--black"></div>
                </div>
                </div>
                <div className="d-flex flex-row justify-content-between mb-8">
                    <div className="d-flex flex-column box-icon w-25 xs-m-auto">
                        <div className="icon d-flex ">
                            <img alt="" src={towing}></img>
                        </div>
                        <div className="mt-2  d-flex flex-column icon-desc">
                            odholownie pojazdu z miejsca wypadku
                        </div>
                    </div>
                    <div className="d-flex flex-column box-icon w-25 xs-m-auto">
                        <div className="icon d-flex">
                            <img alt="" src={repair}></ img>
                        </div>
                        <div className="mt-2  d-flex flex-column icon-desc">
                            naparawa uszkodzonego auta
                        </div>
                    </div>
                    <div className="d-flex flex-column box-icon w-25 xs-m-auto">
                        <div className="icon d-flex">
                        <img alt="" src={rental}></ img>
                        </div>
                        <div className="mt-2 d-flex flex-column icon-desc">
                            samochód zastępczy
                        </div>
                    </div>
                    <div className="d-flex flex-column box-icon w-25 xs-m-auto">
                        <div className="icon d-flex">
                        <img alt="" src={insurance}></ img>
                        </div>
                        <div className="mt-2 d-flex flex-column icon-desc">
                            wyzsze odszkodowanie
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column">
                <h1 className="text-align-center mb-0">A to wszytko bez wyciąganie portfela!</h1>
                <h1 className="mb-8 text-align-center mt-02">Koszty w całości pokrywa OC sprawcy</h1>
                <div className="d-flex justify-content-center mb-8">
                    <ButtonAction label={'Skontaktuj się >'} onClick={e => this.goTo(e)}></ButtonAction>
                </div>
                </div>
            </div>
        );
    }
}
export default IconBar;