import React from "react";


class CarouselLeftArrow extends React.Component {
    render() {
        return (
          <a
            href="#"
            className="carousel__arrow carousel__arrow--left"
            onClick={this.props.onClick}
          >
          </a>
        );
    }
}

class CarouselRightArrow extends React.Component {
    render() {
        return (
          <a
            href="#"
            className="carousel__arrow carousel__arrow--right"
            onClick={this.props.onClick}
          >
          </a>
        );
    }
}

class CarouselSlide extends React.Component {
    render() {
        return (
          <li
            className={
              this.props.index == this.props.activeIndex
                ? "carousel__slide carousel__slide--active"
                : "carousel__slide"
            }
          >
            <img className="carousel__image" src={this.props.slide.background.fluid.src} alt=""></img>
            <div className="carousel_title_box carousel_title_box--upper">
            <p className="carousel-slide__title carousel-slide__title--one">{this.props.slide.titleOne}</p>
            </div>
            <div className="carousel_title_box carousel_title_box--bottom">
            <p className="carousel-slide__title carousel-slide__title--two">{this.props.slide.titleTwo}</p>
            <div className="line__box">
            <div className="line__dashed line__dashed--white line__dashed--bottom"></div>
            </div>
            </div>
          
          </li>
        );
      }
}


class Carousel extends React.Component {
    constructor(props) {
        super(props);

        this.goToPrevSlide = this.goToPrevSlide.bind(this);
        this.goToNextSlide = this.goToNextSlide.bind(this);
      
        this.state = {
          activeIndex: 0
        };

        
    }

    goToPrevSlide(e) {
        e.preventDefault();
    
        let index = this.state.activeIndex;
        let { slides } = this.props;
        let slidesLength = slides.length;
    
        if (index < 1) {
          index = slidesLength;
        }
    
        --index;
    
        this.setState({
          activeIndex: index
        });
    }

    goToNextSlide(e) {
        e.preventDefault();
      
        let index = this.state.activeIndex;
        let { slides } = this.props;
        let slidesLength = slides.length - 1;
    
        if (index === slidesLength) {
          index = -1;
        }
    
        ++index;
    
        this.setState({
          activeIndex: index
        });
    }
    
    componentDidMount(){
        this.autoRun();
    }

    autoRun() {
      let index = this.state.activeIndex;
      let {slides} = this.props;
      let slidesLength = slides.length -1;
      if (index === slidesLength) {
        index = -1;
      }
      ++index;

      this.setState({
        activeIndex: index
      });

      setTimeout(() => this.autoRun(), 4000);
    }

    render() {
        return (
          <div className="carousel xs-hidden xs-overflow-hidden">
            <CarouselLeftArrow onClick={e => this.goToPrevSlide(e)} />
    
            <ul className="carousel__slides">
              {this.props.slides.map(({node}, index) =>
                <CarouselSlide
                  key={index}
                  index={index}
                  activeIndex={this.state.activeIndex}
                  slide={node}
                />
              )}
            </ul>
    
            <CarouselRightArrow onClick={e => this.goToNextSlide(e)} />
          </div>
        );
      }
}

export default Carousel;