import React from "react";
import ButtonAction from "./ButtonAction";
import { navigate } from "gatsby"
import img from "../images/photo_mechanic.png"

class HowWeWorks extends React.Component {
   
    goTo(e){
        e.preventDefault();

        navigate("/service/")
    }

    render() {
        return (
            <div className="mt-8">
                <h1>Jesteś poszkodowany w wypadku?</h1>
            <div className="d-flex flex-row">
                <div className="d-flex flex-column w-50 pr-4">
                <div className="line__box d-flex ">
                    <div className="line__dashed line__dashed--black"></div>
                </div>
                    <p className="">Każdemu poszkodowanemu w wypadku samochodowym należy się odszkodowanie wypłacane z OC sprawcy. Koszty te pokrywają zarówno odholowanie oraz naprawę uszkodzonego samochodu, jak iyczenie auta zastępczego.</p>
                    <p>Jednak ubezpieczyciele w ponad 90% przypadków zaniają koszty naprawy. Znami sie to nie stanie!</p>
    
                    <p className="green-color text-uppercase">
                        Otrzymasz tyle, ile ci sie należy i ile faktycznie będzie kosztowała cała naprawa</p>
    
                    <p>Zyskaj nawet do kilku tysięcy złotych.</p>
                    <div className="d-flex justify-content-center mb-xs-4">
                    <ButtonAction label={'Zobacz jak dzialamy >'} onClick={e => this.goTo(e) }></ButtonAction>
                    </div>
                </div>
                <div className="d-flex flex-row w-50">
                    <div className="img-wrapper">
                        <img alt="" src={img}></img>
                    </div>
                </div>
        
            </div>
            </div>
        );
    }
}

export default HowWeWorks;