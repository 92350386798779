import React from 'react';
import styled from 'styled-components';

import { graphql } from 'gatsby';
import Layout from './../components/Layout';

import HowWeWorks from '../components/HowWeWorks';
import Carousel from '../components/Carousel/Carousel';
import GuideBox from '../components/GuideBox';
import IconBar from '../components/IconBar';
import CarFleet from '../components/CarFleet';
import RecentBlogPosts from '../components/RecentBlogPosts';

import mbaner from '../images/mbaner.png';


const StyledContainer = styled.div`
  `;

const Index = ({data}) => {
  const carouselSlidesData = data.allDatoCmsCarouselImage.edges;
  const recentPosts = data.allDatoCmsPost.edges;
  
  const renderRecentPost = () => {
      if(recentPosts.length){
        return (
          <RecentBlogPosts showButton={true} title="Najpopularniejsze wpisy" posts={recentPosts}></RecentBlogPosts>
        );
      }
      
  }


  return (
    <Layout>
    <StyledContainer>
    <div className="img-wrapper xs-only">
          <img src={mbaner} alt=""></img>
          <div className="main-padding-left main-padding-rigth justify-content-center top-position position-absolut w-100 d-flex flex-column h-100">
            <div className="d-flex flex-column">
              <div className="mask-bg w-100 d-flex mb-4">
                <div className="fs-2 white-color w-100 py-2 text-align-center">Wypadek <br></br>nie z twojej winy?</div>
              </div>
              <div className="d-flex justity-content-center">
                <a href="tel:+48570757300" className='btn btn-rounded green-bg main-color text-align-center fixed-size'>
                    Zadzwoń
                </a>
              </div>
            </div>
          </div>
        </div>
    <Carousel slides={carouselSlidesData}></Carousel>
        <div className="main-wrapper">
          <HowWeWorks></HowWeWorks>
          <GuideBox></GuideBox>
          <IconBar></IconBar>
        </div>
        <CarFleet></CarFleet>
        <div className="main-wrapper">
          {renderRecentPost()}
        </div>
    </StyledContainer>
  </Layout>
  );

  };

export default Index;


export const query = graphql`
query {
  allDatoCmsCarouselImage {
    edges {
      node {
        titleTwo
        titleOne
        background {
          fluid {
            src
          }
        }
      }
    }
  },
  allDatoCmsPost(sort: {fields: meta___publishedAt, order: DESC}, limit: 5) {
    edges {
      node {
        id
        meta {
          publishedAt
        }
        content
        title
        description
        slug
        thumbnail {
          url
        }
      }
    }
  }
}
`;

