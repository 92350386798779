import React from "react";
import ButtonAction from "./ButtonAction";
import img from "../images/advice.png";

class GuideBox extends React.Component {
   
    goTo(e) {
        e.preventDefault();

       console.log('click to do');
    }

    render(){
        return(
            <div className="mt-8">
                <div className="d-flex flex-row xs-inverse">
                    <div className="d-flex flex-row w-50 ">
                        <div className="img-wrapper">
                            <img alt="" src={img}></img>
                        </div>
                    </div>
                    <div className="d-flex flex-column w-50 pl-xs-0 pl-4">
                        <h1>Jak zachować się podczas wypadku</h1>
                            <div className="line__box d-flex ">
                                <div className="line__dashed line__dashed--black"></div>
                            </div>
                        <p>Na bazie lat doświadczeń przygotowaliśmy specjalny poradnik, z którego dowiesz się, jak zachować się na miejscu wypadku. Gotowe porady, schematy zachowania, niezbędne numery tlefonu i wiele innych praktycznych informacji.</p>
                        <p>Nie daj się opanować stresowi i zachowaj zimną krew podczas kolizji. Odbierz swój poradnik i przygotuj się na wszelki wypadek juz teraz.</p>
                        <div className="d-flex justify-content-center mb-xs-4">
                    <ButtonAction label={'Pobierz poradnik >'} onClick={e => this.goTo(e) }></ButtonAction>
                    </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default GuideBox;